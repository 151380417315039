<template>
  <div>
      <!-- <div class="log-out-holder">
          <button type="button"  @click="Logout" class="p-button-outlined p-button-secondary">
            <i class="pi pi-sign-out logout-btn-icon"></i>
            <span class="p-ml-2 p-mr-2 p-text-bold">Logout</span>
        </button>
      </div> -->
    <!-- <span class="p-float-label">
        <InputText class="p-mb-2 p-mt-2" type="text" placeholder="Please Insert Pin" v-model="userPin" @keyup.enter="loadPinz" />
    </span>
    <div>
        <Button label="Submit" type="submit" @click="loadPinz" />
    </div> -->
  </div>
</template>

<script>
import firebase from 'firebase'
import {ref, onBeforeMount} from 'vue'
import { useRouter } from 'vue-router';
// import InputText from 'primevue/inputtext';
// import Button from 'primevue/button';
// import Avatar from 'primevue/avatar';
export default {
    // components: {InputText, Button, Avatar},
    setup(){
        // const userPin = ref("");
        const router = useRouter();
        const db = firebase.firestore();
        // const image = ref("");
        const Logout = () =>{
            firebase
            .auth()
            .signOut()
            .then(() => console.log("Signed Out"), localStorage.removeItem("ploType"), localStorage.removeItem("ploUserName"), localStorage.removeItem("ploUserEmail"), localStorage.removeItem("ploUserImg"), localStorage.removeItem("ploUid"),localStorage.removeItem("ploLogout")
            )
            .catch(err => alert(err.message));
        }
        onBeforeMount(()=>{
            // Logout()
            if(localStorage.getItem("ploLogout") === "logout"){
                // console.log('From logout pin page: ',localStorage.getItem("ploUid"));
                localStorage.removeItem("ploLogout")
                const UserSignOut = async id =>{
                    const signUserOut = await db.collection('user').doc(id).update({active: false, lastLive: firebase.firestore.Timestamp.fromDate(new Date())})
                    Logout()
                    return signUserOut
                }
                UserSignOut(localStorage.getItem("ploUid"))
                 
            }

            else if(localStorage.getItem("ploType") !== null){
                router.replace('/')
            }
            else{
            // console.log('hi1');
            let fbd = ref([])
            let fbdCheck = ref([])
            let userz = ref([])
            let userzCheck = ref([])
            let userRole = ref([])
            let q1 = ref()
            // console.log(parseInt(userPin.value))

            db.collection('domain').get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    fbd.value.push(doc.data())   
                }),
                fbdCheck.value = (fbd.value.map(a => a.name))
                // console.log(fbdCheck.value);
                // console.log(localStorage.getItem("ploUserEmail").split('@')[1]);

                if( (fbdCheck.value).indexOf( (localStorage.getItem("ploUserEmail").split('@')[1]) ) > -1 ){
                    console.log('Domain Authorized');
                    fbd.value = []
                    fbdCheck.value = []
                    // console.log('fbd',fbd.value);

                    /*************************/

                    db.collection('user').get().then((querySnapshot) => {
                        querySnapshot.forEach((doc) => {
                            userz.value.push(doc.data())   
                        }),
                        userzCheck.value = (userz.value.map(a => a.email))

                        if( (userzCheck.value).indexOf( (localStorage.getItem("ploUserEmail") ) ) > -1 ){
                            console.log("User Authorized");
                            userz.value = []
                            userzCheck.value = []

                            db.collection('user').where("email", "==", localStorage.getItem("ploUserEmail")).get().then((querySnapshot) => {
                                querySnapshot.forEach((doc) => {
                                    fbd.value.push(doc.data())   
                                }),
                                
                                fbdCheck.value = (fbd.value.map(a => a.role))
                                
                                userRole.value = JSON.stringify(fbdCheck.value)

                                //Check if the logged in user is Admin
                                if((userRole.value).indexOf("adm") > -1 ){
                                    db.collection('user').where("email", "==", localStorage.getItem("ploUserEmail")).get()
                                        .then(function(querySnapshot) {
                                            querySnapshot.forEach(function(doc) {
                                                // doc.data() is never undefined for query doc snapshots
                                                // console.log(doc.id, " => ", doc.data());
                                                // console.log(doc.id)
                                                q1= doc.id
                                                // console.log(q1);
                                                localStorage.setItem("ploUid", q1);
                                                db.collection('user').doc(q1).update({active: true, lastLive: firebase.firestore.Timestamp.fromDate(new Date())})
                                                q1 = ''
                                            });
                                        })
                                    // console.log(q1);
                                    fbd.value = []
                                    fbdCheck.value = []
                                    userRole.value = []
                                    console.log('Admin Authorized');
                                    localStorage.setItem("ploType", "adm")
                                    router.replace('/')
                                }
                                //Check if the logged in user is Manager
                                else if((userRole.value).indexOf("mgr") > -1 ){
                                    db.collection('user').where("email", "==", localStorage.getItem("ploUserEmail")).get()
                                        .then(function(querySnapshot) {
                                            querySnapshot.forEach(function(doc) {
                                                // doc.data() is never undefined for query doc snapshots
                                                // console.log(doc.id, " => ", doc.data());
                                                // console.log(doc.id)
                                                q1= doc.id
                                                // console.log(q1);
                                                localStorage.setItem("ploUid", q1);
                                                db.collection('user').doc(q1).update({active: true, lastLive: firebase.firestore.Timestamp.fromDate(new Date())})
                                                q1 = ''
                                            });
                                        })
                                    fbd.value = []
                                    fbdCheck.value = []
                                    userRole.value = []
                                    console.log('Manager Authorized');
                                    localStorage.setItem("ploType", "mgr")
                                    router.replace('/')
                                }
                                //Logged in user is Staff
                                else{
                                    db.collection('user').where("email", "==", localStorage.getItem("ploUserEmail")).get()
                                        .then(function(querySnapshot) {
                                            querySnapshot.forEach(function(doc) {
                                                // doc.data() is never undefined for query doc snapshots
                                                // console.log(doc.id, " => ", doc.data());
                                                // console.log(doc.id)
                                                q1= doc.id
                                                // console.log(q1);
                                                localStorage.setItem("ploUid", q1);
                                                db.collection('user').doc(q1).update({active: true, lastLive: firebase.firestore.Timestamp.fromDate(new Date())})
                                                q1 = ''
                                            });
                                        })
                                    fbd.value = []
                                    fbdCheck.value = []
                                    userRole.value = []
                                    console.log('Staff Authorized');
                                    localStorage.setItem("ploType", "stf")
                                    router.replace('/')
                                }

                            })

                        }
                        else{
                            db.collection('user').add({email:localStorage.getItem("ploUserEmail"), name:localStorage.getItem("ploUserName"), role: "stf", active: true, lastLive: firebase.firestore.Timestamp.fromDate(new Date())})
                            db.collection('user').where("email", "==", localStorage.getItem("ploUserEmail")).get()
                                .then(function(querySnapshot) {
                                    querySnapshot.forEach(function(doc) {
                                        // doc.data() is never undefined for query doc snapshots
                                        // console.log(doc.id, " => ", doc.data());
                                        // console.log(doc.id)
                                        q1= doc.id
                                        // console.log(q1);
                                        localStorage.setItem("ploUid", q1);
                                        q1 = ''
                                    });
                                })
                            userz.value = []
                            userzCheck.value = []
                            console.log("New Staff Added");
                            localStorage.setItem("ploType", "stf")
                            console.log('Staff Authorized');
                            router.replace('/')
                        }
                    })

                }
                else{
                    fbd.value = []
                    fbdCheck.value = []
                    alert("You are not authorized Ploutus Staff")
                    Logout()
                }

            })

            }

            

            

            /************************/
        });


        //Check if the logged in user is Admin
            // db.collection('admin').get().then((querySnapshot) => {
            //     querySnapshot.forEach((doc) => {
            //         fbd.value.push(doc.data())   
            //     }),
            //     fbdCheck.value = (fbd.value.map(a => a.email))
            //     // console.log(fbdCheck.value);
            //     // console.log(localStorage.getItem("ploUserEmail").split('@')[1]);

            //     if( (fbdCheck.value).indexOf( (localStorage.getItem("ploUserEmail") ) ) > -1 ){
            //         console.log('Admin Authorized');
            //         fbd.value = []
            //         fbdCheck.value = []
            //         localStorage.setItem("ploType", 'adm'),
            //         router.replace('/')
            //         // console.log('fbd',fbd.value);
            //     }
            //     else{
            //         //Check if the logged in user is Manager
            //         db.collection('manager').get().then((querySnapshot) => {
            //             querySnapshot.forEach((doc) => {
            //                 fbd.value.push(doc.data())   
            //             }),
            //             fbdCheck.value = (fbd.value.map(a => a.email))
            //             // console.log(fbdCheck.value);
            //             // console.log(localStorage.getItem("ploUserEmail").split('@')[1]);

            //             if( (fbdCheck.value).indexOf( (localStorage.getItem("ploUserEmail") ) ) > -1 ){
            //                 fbd.value = []
            //                 fbdCheck.value = []

            //                 console.log('Manager Authorized');
            //                 router.replace('/')
            //                 // console.log('fbd',fbd.value);
            //             }
            //             else{
            //                 fbd.value = []
            //                 fbdCheck.value = []

            //                 console.log('Staff Authorized');
            //                 router.replace('/')
            //                 // alert("You are not authorized Ploutus Staff")
            //                 // Logout()
            //             }

            //         })
            //     }

            // })
            
            // image.value= localStorage.getItem("ploUserImg");

        // const pinCollection = db.collection('pin')


        // const loadPins = () => {
        //     const pins = ref([])
        //     pinCollection.onSnapshot(snapshot =>{
        //         pins.value = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data()}))
        //     })
        //     return console.log(pins) 
        // }

        // const dbPins = loadPins()


        // const loadPinz = () => {

        //     let pinz = ref([])
        //     let check = ref([])
        //     // console.log(parseInt(userPin.value))

        //     db.collection('pin').get().then((querySnapshot) => {
        //         querySnapshot.forEach((doc) => {
        //             pinz.value.push(doc.data())   
        //         }),
        //         check.value = (pinz.value.map(a => a.code))
        //         // console.log(check.value);
        //         if((check.value).indexOf(parseInt(userPin.value)) === 0){
        //             // return console.log('admin'),
        //             localStorage.setItem("ploType", 'adm'),
        //             check.value = null,
        //             router.replace('/')
        //         }
        //         else if((check.value).indexOf(parseInt(userPin.value)) === 1){
        //             // return console.log('manager'),
        //             localStorage.setItem("ploType", 'mgr'),
        //             check.value = null,
        //             router.replace('/')
        //         }
        //         else if((check.value).indexOf(parseInt(userPin.value)) === 2){
        //             // return console.log('staff'),
        //             localStorage.setItem("ploType", 'stf'),
        //             check.value = null,
        //             router.replace('/')
        //         }
        //         else{return alert('Please Insert Correct Pin')}
        //         //    return console.log((check.value).indexOf(14579) > -1)
        //         //    return console.log((pinz.value.map(a => a.code)).indexOf(14579) > -1)
        //     })
        // }

        // const Logout = () =>{
        //     firebase
        //     .auth()
        //     .signOut()
        //     .then(() => console.log("Signed Out"), localStorage.removeItem("ploType"), localStorage.removeItem("ploUserName"), localStorage.removeItem("ploUserEmail"), localStorage.removeItem("ploUserImg"), localStorage.removeItem("ploUid")
        //     )
        //     .catch(err => alert(err.message));
        // }
        // const dbPinz = loadPinz()
            // let pins = [];
                
        //   db.collection('pin').onSnapshot(res =>{
        //       console.log('Hello',res)
        //     const changes = res.docChanges()
        //     changes.forEach(change=> {
        //       if(change.type === 'added'){
        //         this.projects.push({
        //           ...change.doc.data(),
        //           id: change.doc.id
        //         })
        //       }
        //     })
        //   });
        
        return{
            // dbPins,
            // dbPinz,
            // image,
            // userPin,
            // loadPinz,
            Logout
        }
    }
}
</script>

<style>/*
.log-out-holder{
    display: flex;
    justify-content: flex-end;
}
.logout-btn-icon{
    margin-top: 2px;
    font-size: 0.8rem;
}*/
</style>